import './Forms.css';
import { closeAllForms } from './../../controllers/AppConstants';

function Register(props) {
    return (
        <div id="registerForm" className="modal fade" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header" style={{color: 'black'}}>
                        <div className="col-3" style={{paddingTop: '7px'}}>
                            ← Register
                        </div>
                        
                        <div className="col-6 text-center">
                            <a href="#" className='logo black'>Logo Here</a>
                        </div>

                        <div className="col-3 d-flex justify-content-end" >
                            <button type="button" className="btn btn-white" data-bs-dismiss="modal">✖</button>
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="container " style={{ margin: '10px auto' }}>
                            <div className="text-center">
                                <span style={{fontSize: '18px'}}>
                                    Register now to play the world's biggest lotteries
                                </span>
                                
                                <div className="col-12" style={{backgroundColor: 'rgb(240, 240, 255)', fontSize: '14px', borderRadius: '5px', maxWidth:'420px', margin: '15px auto', padding: '15px'}}>
                                    Join 1 Crore of other people getting to play the biggest jackpots, with the best mobile features and great offers.
                                    <br /><br />
                                    Dream Bigger with <span className='colinasRegular lightBlue'>Indian Roulette</span>
                                </div>

                            </div>

                            <form>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control"  placeholder="First name" />
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control"  placeholder="Last name" /> 
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-12">
                                        <input type="tel" className="form-control" placeholder="Phone Number" />
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <input type="date" className="form-control"  placeholder="Date Of Birth" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <input type="email" className="form-control"  placeholder="Email" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-12">
                                        <input type="password" className="form-control"  placeholder="Password" />
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <input type="password" className="form-control"  placeholder="Confirm Password" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control"  placeholder="Address" />
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control" placeholder="City" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control"  placeholder="State" />
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <input type="text" className="form-control" placeholder="Country" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <input type="number" className="form-control"  placeholder="Pin Code"/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                    <label className="custom-control custom-checkbox checkbox-lg" style={{marginBottom:'20px'}}>
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <div className="custom-control-label" htmlFor="customCheck1" style={{display: 'inline-block', marginLeft:'5px'}}>
                                            Accept our <a href="#" target="_blank">Terms & Conditions</a>
                                        </div>
                                    </label>
                                    
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning" style={{ borderRadius: '50px', width: '250px', margin: '10px auto', padding: '10px 0px' }} onClick={()=>{props.onRegister(); closeAllForms();}}>CREATE A NEW ACCOUNT</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;

/*
function Register() {
    return (
        <div className="container " style={{fontSize: '20px' }}>
            <div className="row ">
                <div className="col-3" style={{color: 'green' }}>
                    &#8592; Login
                </div>
                <div className="col-6 text-center" style={{margin: '10px auto' }}>
                    <img src="./assets/logo.svg" alt="" style={{width: '200px', backgroundColor: 'green' }}/>
                </div>
                <div className="col-3" style={{textAlign: 'right' }}>
                    &#10006; 
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center" style={{margin: '20px auto', color: 'grey' }}>
                    Register now to play the world's biggest lotteries
                </div>
            </div>
            <div className="row" style={{backgroundColor: 'rgb(240, 240, 255)', fontSize: '14px', padding: '10px 0px'}}>
                <div className="col-12 text-center " >
                    Join 1 Crore of other people getting to play the biggest jackpots, with 
                    <div>
                        the best mobile features and great offers.
                    </div>
                    <div style={{marginTop: '10px' }}>
                        Dream Bigger with Lottoland.
                    </div>
                </div>
            </div>
            <div className="row" style={{margin: '30px auto' }}>
                <div className="col-12">
                    <form>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1  "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="email" className="form-control"  placeholder="Email" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1  "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="password" className="form-control"  placeholder="Password" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1  "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control"  placeholder="First name" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control"  placeholder="Last name" /> 
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="tel" className="form-control" placeholder="Phone Number" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="date" className="form-control"  placeholder="Date Of Birth" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control" placeholder="Country"/>
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control" placeholder="City" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control" placeholder="State"/>
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="text" className="form-control"  placeholder="Address" />
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                            <div className="col-lg-8 col-md-10 col-10 ">
                                <input type="number" className="form-control"  placeholder="Pin Code"/>
                            </div>
                            <div className="col-lg-2 col-md-1 col-1 "></div>
                        </div>
                        
                    </form>
                </div>
            </div>
            <div className="row" style={{margin: '30px auto' }}>
                <div className="col-lg-2 col-md-1 col-1"></div>
                <div className="col-lg-8 col-md-10 col-10 ">
                    <button type="button" className="btn btn-success" style={{borderRadius: '50px', width: '100%', fontSize: '18px' }}>CREATE A NEW ACCOUNT</button>
                </div>
                <div className="col-lg-2 col-md-1 col-1"></div>
            </div>
        </div>

    );
}

export default Register;

*/