import React, { useState, useEffect } from 'react';
import Banner from "../components/banner/Banner";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import BannerBottom from "../components/bannerBottom/BannerBottom";
import Body from "../components/body/Body";
import Login from "../components/forms/Login";
import Register from "../components/forms/Register";
import * as AppService from 'AppService';

function HomePage() {
  const [games, setGamesState] = useState({});
  const [loggedIn, setLoginState] = useState(false);
  
  useEffect(()=>{
    AppService.GetGamesConfig().then((response)=>{
      setGamesState(response);
    });
  }, []);

  function logout(e) {
    setLoginState(false);
  }

  function login(_validLogin) {
    console.log("_validLogin:", _validLogin);
    setLoginState(_validLogin);
  }

  function register(e) {
    console.log("User have registered successfuly");
  }

  return (
    <React.Fragment>
      <Header isLoggedIn={loggedIn} onLogout={logout} />
      <Banner />
      <BannerBottom />
      <Body games={games} />
      <Footer elements={["footerIcons", "disclaimer" ,"copyright"]} />
      <Login onLogin={login} />
      <Register onRegister={register} />
    </React.Fragment>
  );
}

export default HomePage;
