import './Pages.css';
import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import * as AppService from 'AppService';

function GamePage() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const gameId = searchParams.get('gameId');
  const { gameId } = useParams(); //Captures React router parameters
  const [games, setGamesState] = useState({});
  const [loggedIn, setLoginState] = useState(true);
  const [game, setGameState] = useState(null);

  useEffect(()=>{
    AppService.GetGamesConfig().then((response)=>{
      setGamesState(response);
    });
  }, []);

  useEffect(()=>{
    if(games[gameId]){
      setGameState(games[gameId])
    }
    console.log("game:", games[gameId]);
  }, [games]);

  function logout(e) {
    setLoginState(false);
  }

  return (
    <React.Fragment>
      <Header isLoggedIn={loggedIn} onLogout={logout} />
      <div id="gameContainer" className='gameContainer fluid d-flex justify-content-center align-items-center' style={{ flexGrow: 1 }}>
          {(game)?(
              <iframe className="wh100" src={game.src}></iframe>
          ):(
              "Loading game"
          )}
      </div>
      <Footer elements={["Remove_footerIcons", "Remove_disclaimer" ,"copyright"]} />
    </React.Fragment>
  );
}

export default GamePage;