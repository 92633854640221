import { Modal } from 'bootstrap';

var forms = {};

export function showLoginForm(e) {
    let myModal = new Modal(document.getElementById('loginForm'), {
        keyboard: false
    });
    if(!forms.loginForm){
        forms.loginForm = myModal;
    }

    if(forms.loginForm){forms.loginForm.toggle();}
}

export function showRegisterForm(e) {    
    let myModal = new Modal(document.getElementById('registerForm'), {
        keyboard: false
    });
    if(!forms.registerForm){
        forms.registerForm = myModal;
    }

    if(forms.registerForm){forms.registerForm.toggle();}
}

export function closeAllForms(e){
    Object.keys(forms).map((form)=>{
        forms[form].hide();
    });
}