import { Debug } from 'utils';
import { CONFIG } from './env';

export function GetGamesConfig() {
    Debug("Games config path:", CONFIG.BASE_URL + CONFIG.GAMES_CONFIG);
    return fetch(CONFIG.BASE_URL + CONFIG.GAMES_CONFIG,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    ).then(function (response) {
        return response.json();
    }).then(function (gamesConfig) {
        let Games = {};

        gamesConfig.map((game) => {
            Games[game.id] = {
                id: game.id,
                name: game.name,
                description: game.description,
                thumbnail: game.thumbnail,
                src: CONFIG.BASE_URL + CONFIG.GAMES_DIR + game.src,
                tech: game.tech,
                template: game.template,
                status : game.status
            }
        });

        return Games;
    });
}